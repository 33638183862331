import { render, staticRenderFns } from "./IdentificationWorkerColumn.vue?vue&type=template&id=98422956&scoped=true&"
import script from "./IdentificationWorkerColumn.vue?vue&type=script&setup=true&lang=ts&"
export * from "./IdentificationWorkerColumn.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./IdentificationWorkerColumn.vue?vue&type=style&index=0&id=98422956&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98422956",
  null
  
)

export default component.exports